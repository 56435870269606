import { useRef } from 'react';
import { Actions } from '../model/GameState';
import { getPiecePosition } from '../model/GameStateUtils';

export default function Piece(props) {
    const { index, group, config, dispatch, dragOffset, setDragOffset } = props;

    const mouseDragPosition = useRef({ x: 0, y: 0 });

    const row = Math.floor(index / config.numCols);
    const col = index % config.numCols;

    const [left, top] = getPiecePosition(index, group, config);

    const wrapperStyle = {
        position: 'absolute',
        left: `${left + dragOffset.dx}px`,
        top: `${top + dragOffset.dy}px`,
        transform: `rotate(${group.rotation * 90}deg)`,
        zIndex: group.zIndex,

        filter: 'drop-shadow(0 0 1px rgb(0, 0, 0))',
    };

    const style = {
        width: `${config.unitWidth + config.overlap * 2}px`,
        height: `${config.unitHeight + config.overlap * 2}px`,
        backgroundImage: `url(${config.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${config.numCols * config.unitWidth}px ${
            config.numRows * config.unitHeight
        }px`,
        backgroundPositionX: `${config.overlap - col * config.unitWidth}px`,
        backgroundPositionY: `${config.overlap - row * config.unitHeight}px`,

        clipPath: `url(#clipPath${index})`,
    };

    const handleMouseDrag = (e) => {
        e.preventDefault();

        const dx = e.clientX - mouseDragPosition.current.x;
        const dy = e.clientY - mouseDragPosition.current.y;

        setDragOffset({ dx, dy });
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        document.onmouseup = null;
        document.onmousemove = null;

        setDragOffset({ dx: 0, dy: 0 });

        const dx = e.clientX - mouseDragPosition.current.x;
        const dy = e.clientY - mouseDragPosition.current.y;

        dispatch({
            type: Actions.OnDrop,
            payload: { piece: index, group: group.refIndex, dx: dx, dy: dy },
        });
    };

    const handleMouseDown = (e) => {
        e.preventDefault();

        if (e.ctrlKey) {
            dispatch({ type: Actions.Rotate, payload: { piece: index, group: group.refIndex } });
            return;
        }

        mouseDragPosition.current.x = e.clientX;
        mouseDragPosition.current.y = e.clientY;

        document.onmousemove = handleMouseDrag;
        document.onmouseup = handleMouseUp;

        dispatch({ type: Actions.BringToTop, payload: { piece: index, group: group.refIndex } });
    };

    return (
        <div className="piece-wrapper" style={wrapperStyle}>
            <div className="piece" style={style} onMouseDown={handleMouseDown}></div>
        </div>
    );
}
