import React, { useState } from 'react';
import NewGamePuzzleDetails from './NewGamePuzzleDetails';

const puzzles = [
    {
        id: 1,
        name: 'Woods',
        url: '/images/sample01.jpg',
    },
    {
        id: 2,
        name: 'Hot Pot',
        url: '/images/sample02.jpg',
    },
    {
        id: 3,
        name: 'Birthday Card',
        url: '/images/sample03.jpg',
    },
    {
        id: 4,
        name: 'Puzzles',
        url: '/images/sample04.jpg',
    },
    {
        id: 5,
        name: 'House',
        url: '/images/sample05.jpg',
    },
    {
        id: 6,
        name: 'Cobblestone Way',
        url: '/images/sample06.jpg',
    },
];

export default function NewGame(props) {
    const { setPuzzleConfig } = props;
    const [selectPuzzleId, setSelectPuzzleId] = useState();

    return (
        <div className="new-game-container">
            <h1 className="new-game-title">New Game</h1>
            <div className="new-game-grid">
                {puzzles.map((puzzle) => (
                    <NewGamePuzzleDetails
                        key={puzzle.id}
                        puzzle={puzzle}
                        selectPuzzleId={selectPuzzleId}
                        setSelectPuzzleId={setSelectPuzzleId}
                        setPuzzleConfig={setPuzzleConfig}
                    />
                ))}
            </div>
        </div>
    );
}
