export default function createGameConfig(puzzle, rows, cols) {
    const config = {
        puzzle: puzzle,
        image: puzzle.url,

        unitWidth: 90,
        unitHeight: 70,
        numRows: rows,
        numCols: cols,

        gap: 25,
        overlap: 20,
        curveGuide: 7,

        tabBaseMin: 15,
        tabBaseMax: 20,
        tabTopMin: 5,
        tabTopMax: 10,
        tabBaseOffsetMax: 5,
        tabTopOffsetMax: 0,

        distanceTolerance: 30,
    };

    const clipPathConfig = genClipPathConfig(config);

    return { ...config, ...clipPathConfig };
}

function genClipPathConfig(config) {
    const vTabs = genTabConfiguration(config);
    const hTabs = genTabConfiguration(config);

    return { vTabs, hTabs };
}

function genTabConfiguration(config) {
    const rows = config.numRows;
    const cols = config.numCols;
    const a = new Array(rows * cols);

    for (let i = 0; i < a.length; ++i) {
        a[i] = {
            mode: Math.floor(Math.random() * 2) * 2 - 1,
            tabBase: genRandomFromRange(config.tabBaseMin, config.tabBaseMax),
            tabTop: genRandomFromRange(config.tabTopMin, config.tabTopMax),
            tabBaseOffset: genRandomFromRange(-config.tabBaseOffsetMax, config.tabBaseOffsetMax),
            tabTopOffset: genRandomFromRange(-config.tabTopOffsetMax, config.tabTopOffsetMax),
        };
    }

    return a;
}

function genRandomFromRange(min, max) {
    return Math.random() * (max - min) + min;
}

function getTabs(config, row, col) {
    const toIndex = (row, col) => config.numCols * row + col;
    const getVTab = (row, col) => config.vTabs[toIndex(row, col)];
    const getHTab = (row, col) => config.hTabs[toIndex(row, col)];

    const noTab = { mode: 0 };

    return {
        left: col == 0 ? noTab : getVTab(row, col - 1),
        right: col == config.numCols - 1 ? noTab : reverseTab(getVTab(row, col)),
        top: row == 0 ? noTab : getHTab(row - 1, col),
        bottom: row == config.numRows - 1 ? noTab : reverseTab(getHTab(row, col)),
    };
}

function reverseTab(tab) {
    return { ...tab, mode: -tab.mode };
}

export function getSVGClipPath(config, index) {
    const row = Math.floor(index / config.numCols);
    const col = index % config.numCols;

    return genSVGClipPath(config, row, col);
}

function genSVGClipPath(config, row, col) {
    const lBase = config.overlap;
    const rBase = config.unitWidth + config.overlap;
    const tBase = config.overlap;
    const bBase = config.unitHeight + config.overlap;

    const tabs = getTabs(config, row, col);

    const tEdge = drawEdge2(config, tabs.top, 0, { x: lBase, y: tBase }, { x: rBase, y: tBase });

    const rEdge = drawEdge2(
        config,
        tabs.right,
        270,
        { x: rBase, y: tBase },
        { x: rBase, y: bBase }
    );

    const bEdge = drawEdge2(
        config,
        tabs.bottom,
        180,
        { x: rBase, y: bBase },
        { x: lBase, y: bBase }
    );

    const lEdge = drawEdge2(config, tabs.left, 90, { x: lBase, y: bBase }, { x: lBase, y: tBase });

    return `
        M${lBase} ${tBase}, ${tEdge} ${rEdge} ${bEdge} ${lEdge} Z`;
}

function drawEdge2(config, tab, baseDir, start, end) {
    if (tab.mode == 0) {
        return `L${end.x}, ${end.y}, `;
    } else {
        const tbFlag = baseDir == 0 || baseDir == 180; // true if Top/Bottom, false if Left/Right.
        const tlSign = baseDir == 0 || baseDir == 90 ? 1 : -1; // Set to 1 for Top/Left, -1 for Bottom/Right.
        const trSign = baseDir == 0 || baseDir == 270 ? 1 : -1; // Set to 1 for Top/Right, -1 for Bottom/Right.
        //console.log({ tbFlag, tlSign, trSign });

        const unitLength = (tbFlag ? end.x - start.x : end.y - start.y) * trSign;
        const elementLength = unitLength + config.overlap * 2;
        //console.log({ unitLength, elementLength });
        const base1 = (elementLength - tab.tabBase * trSign) / 2 + tab.tabBaseOffset;
        const base2 = (elementLength + tab.tabBase * trSign) / 2 + tab.tabBaseOffset;
        const top1 =
            (elementLength - (tab.tabTop + tab.tabBase) * trSign) / 2 +
            tab.tabBaseOffset +
            tab.tabTopOffset;
        const top2 =
            (elementLength + (tab.tabTop + tab.tabBase) * trSign) / 2 +
            tab.tabBaseOffset +
            tab.tabTopOffset;
        //console.log({ base1, base2, top1, top2 });

        const tabBase = tbFlag ? (start.y + end.y) / 2 : (start.x + end.x) / 2;
        const overLapScaleFactor = 0.8;
        const tabTab = tabBase - tlSign * tab.mode * config.overlap * overLapScaleFactor;
        //console.log({ tabTab });

        if (tbFlag) {
            return drawEdge(
                config,
                tab,
                baseDir,
                start,
                { ...start, x: base1 },
                { x: top1, y: tabTab },
                { x: top2, y: tabTab },
                { ...end, x: base2 },
                end
            );
        } else {
            return drawEdge(
                config,
                tab,
                baseDir,
                start,
                { ...start, y: base1 },
                { y: top1, x: tabTab },
                { y: top2, x: tabTab },
                { ...end, y: base2 },
                end
            );
        }
    }
}

function drawEdge(config, tab, baseDir, start, base1, top1, top2, base2, end) {
    if (tab.mode == 0) {
        return `L${end.x}, ${end.y}, `;
    } else {
        start.dir = baseDir;
        base1.dir = baseDir + Math.sign(tab.mode) * 75;
        top1.dir = baseDir + Math.sign(tab.mode) * 25;
        top2.dir = baseDir + Math.sign(tab.mode) * -25;
        base2.dir = baseDir + Math.sign(tab.mode) * -75;
        end.dir = baseDir;

        return (
            drawCurve(config, start, base1) +
            drawCurve(config, base1, top1) +
            drawCurve(config, top1, top2) +
            drawCurve(config, top2, base2) +
            drawCurve(config, base2, end)
        );
    }
}

function drawCurve(config, start, end) {
    const cgl = config.curveGuide;

    const deg2rad = (d) => d * (Math.PI / 180);

    const dx1 = cgl * Math.cos(deg2rad(start.dir));
    const dy1 = -cgl * Math.sin(deg2rad(start.dir));
    const dx2 = cgl * Math.cos(deg2rad(end.dir));
    const dy2 = -cgl * Math.sin(deg2rad(end.dir));

    return `C ${start.x + dx1} ${start.y + dy1}, 
    ${end.x - dx2} ${end.y - dy2}, ${end.x}, ${end.y}, `;
}
