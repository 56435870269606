import React, { useState } from 'react';
import Piece from './Piece';

const PieceGroup = (props) => {
    const { group, config, dispatch } = props;

    //console.log('Render <PieceGroup>');

    const [dragOffset, setDragOffset] = useState({ dx: 0, dy: 0 });

    return (
        <>
            {group.pieces.map((pi) => {
                return (
                    <Piece
                        key={pi}
                        index={pi}
                        group={group}
                        config={config}
                        dispatch={dispatch}
                        dragOffset={dragOffset}
                        setDragOffset={setDragOffset}
                    ></Piece>
                );
            })}
        </>
    );
};

export default React.memo(PieceGroup);
