import { useState, useEffect } from 'react';
import Game from './Game';
import NewGame from './NewGame';
import { useAuth } from '../contexts/AuthContext';
import '../App.css';

export const AppNameKey = 're-jigsaw';
const PuzzleConfigKey = 'PuzzleConfig';

export default function Dashboard() {
    const { currentUser, anon_sign_in } = useAuth();

    useEffect(async () => {
        try {
            await anon_sign_in();
        } catch (error) {
            console.log('Failed to sign in anonymously: ', error);
        }
    }, []);

    const currentPuzzleConfigKey = `${AppNameKey}.${PuzzleConfigKey}.current`;

    const [puzzleConfig, setPuzzleConfig] = useState(() => {
        const jsonValue = localStorage.getItem(currentPuzzleConfigKey);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    });

    useEffect(() => {
        if (puzzleConfig != null) {
            localStorage.setItem(currentPuzzleConfigKey, JSON.stringify(puzzleConfig));
        }
    }, [puzzleConfig]);

    const clearPuzzleConfig = () => {
        setPuzzleConfig(null);
    };

    if (puzzleConfig) {
        return <Game puzzleConfig={puzzleConfig} clearPuzzleConfig={clearPuzzleConfig} />;
    } else {
        return <NewGame setPuzzleConfig={setPuzzleConfig} />;
    }
}
