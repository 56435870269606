import { useReducer, useEffect } from 'react';
import { initGameState, reduceGameState, Actions } from '../model/GameState';
import SVGClipPaths from './SVGClipPaths';
import PieceGroup from './PieceGroup';
import { AppNameKey } from './Dashboard';
import { database } from '../firebase';

const GameStateKey = 'GameState';

function uploadMoves(localMoves, dispatch) {
    console.log('uploadLocalMoves:', localMoves);

    let count = 0;
    let failed = false;

    for (let i = 0; i < localMoves.length && !failed; ++i) {
        const move = localMoves[i];

        database.moves
            .add({
                uid: 'uid-101', // FIXME
                gameId: 'gameId-101', // FIXME
                groups: move,
                createdAt: database.getCurrentTimestamp(),
            })
            .then((docRef) => {
                console.log('Moves document written with ID: ', docRef.id);
                ++count;
            })
            .catch((error) => {
                console.error('Error adding Moves document: ', error);
                failed = true;
            });
    }

    dispatch({ type: Actions.ClearLocalMove, payload: { count } });
}

export default function Game(props) {
    const { puzzleConfig, clearPuzzleConfig } = props;

    const { puzzle, size } = puzzleConfig;
    const currentGameStateKey = `${AppNameKey}.${GameStateKey}.[${puzzle.id}].${size.rows}x${size.cols}`;

    const [state, dispatch] = useReducer(reduceGameState, puzzleConfig, (config) => {
        const jsonValue = localStorage.getItem(currentGameStateKey);
        if (jsonValue != null) return JSON.parse(jsonValue);
        return initGameState(config);
    });

    useEffect(() => {
        localStorage.setItem(currentGameStateKey, JSON.stringify(state));
    }, [state]);

    const localMoves = state.localMoves;

    useEffect(() => {
        if (localMoves.length > 0) {
            uploadMoves(localMoves, dispatch);
        }
    }, [localMoves]);

    const handleResize = () => {
        const ws = { width: window.innerWidth, height: window.innerHeight };
        dispatch({
            type: Actions.ResizeWindow,
            payload: { ws },
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNewGame = () => {
        clearPuzzleConfig();
    };

    const renderGroup = (group) => {
        return (
            group && (
                <PieceGroup
                    key={group.refIndex}
                    group={group}
                    config={state.config}
                    dispatch={dispatch}
                />
            )
        );
    };

    const renderButtons = () => {
        return (
            <div className="game-buttons-container">
                <button className="btn game__button" onClick={handleNewGame}>
                    New Game
                </button>
                <button className="btn game__button">Scatter</button>
                <button className="btn game__button">Edge Only</button>
            </div>
        );
    };

    return (
        <>
            <SVGClipPaths gameConfig={state.config} />
            <div className="game-container">
                {state.groups.map(renderGroup)}
                {renderButtons()}
            </div>
        </>
    );
}
