// Apply the rotation on values x and y. Return the new x and y values.
export function applyRotation(rotation, x, y) {
    switch (rotation % 4) {
        case 0:
            return [x, y];
        case 1:
            return [-y, x];
        case 2:
            return [-x, -y];
        case 3:
            return [y, -x];
    }
}

// Get the left & top position for a piece of the group.
export function getPiecePosition(index, group, config) {
    const nCols = config.numCols;

    const refRow = Math.floor(group.refIndex / nCols);
    const refCol = group.refIndex % nCols;

    const row = Math.floor(index / nCols);
    const col = index % nCols;

    const rowDelta = config.unitHeight * (row - refRow);
    const colDelta = config.unitWidth * (col - refCol);

    const [dCol, dRow] = applyRotation(group.rotation, colDelta, rowDelta);

    const left = group.refLeft + dCol;
    const top = group.refTop + dRow;

    return [left, top];
}
