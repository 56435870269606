import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const firestore = app.firestore();

// Database schemas:
//
// user:
//  - id [auto]: the unique id
//  - uid: auth.uid
//  - name.
//
// game:
//  - id [auto]: the unique id
//  - host: the uid of the user that created this game
//  - configuration: the initial (or current?) game configuration
//
// moves:
//  - id [auto]: the unique id
//  - uid: auth.uid
//  - gameId: the unique id for a game session
//  - groups: [[1], [2, 3], [4, 5, 6]]
//  - createdAt: server time stamp

export const database = {
    users: firestore.collection('users'),

    moves: firestore.collection('moves'),

    formatDoc: (doc) => {
        return { id: doc.id, ...doc.data() };
    },

    getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

export const auth = app.auth();
export default app;
