import React from 'react';

export default function NewGamePuzzleDetails(props) {
    const { puzzle, selectPuzzleId, setSelectPuzzleId, setPuzzleConfig } = props;

    const imageStyles = {
        backgroundImage: `url(${puzzle.url})`,
    };

    const handleSelect = () => {
        setSelectPuzzleId((prev) => {
            if (prev !== puzzle.id) return puzzle.id;
            else return '';
        });
    };

    const handleUnselect = () => {
        setSelectPuzzleId('');
    };

    const handleSubmit = (sizeConfig) => {
        setPuzzleConfig({ puzzle: puzzle, size: sizeConfig });
    };

    return (
        <div
            className="new-game__puzzle-details-container"
            onMouseEnter={handleSelect}
            onMouseLeave={handleUnselect}
        >
            <div
                className="new-game__puzzle-image"
                style={imageStyles}
                onClick={handleSelect}
            ></div>
            <div className="new-game__puzzle-name" onClick={handleSelect}>
                {puzzle.name}
            </div>
            {selectPuzzleId === puzzle.id ? (
                <div className="new-game__puzzle-size-container">
                    <button
                        className="btn new-game__puzzle-size-btn"
                        onClick={() => handleSubmit({ rows: 5, cols: 6 })}
                    >
                        Easy (30 Pieces)
                    </button>
                    <button
                        className="btn new-game__puzzle-size-btn"
                        onClick={() => handleSubmit({ rows: 8, cols: 10 })}
                    >
                        Medium (80 Pieces)
                    </button>
                    <button
                        className="btn new-game__puzzle-size-btn"
                        onClick={() => handleSubmit({ rows: 10, cols: 12 })}
                    >
                        Expert (120 Pieces)
                    </button>
                </div>
            ) : (
                <div className="new-game__puzzle-size-container"></div>
            )}
        </div>
    );
}
