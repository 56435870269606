import React from 'react';
import { getSVGClipPath } from '../GameConfig';

function SVGClipPaths(props) {
    const { gameConfig } = props;

    const renderClipPath = (index) => {
        const id = `clipPath${index}`;
        const d = getSVGClipPath(gameConfig, index);
        return (
            <clipPath key={index} id={id}>
                <path fill="#FFFFFF" stroke="#000000" d={d} />
            </clipPath>
        );
    };

    const numPieces = gameConfig.numRows * gameConfig.numCols;

    return (
        <>
            <svg>
                <defs>{[...Array(numPieces).keys()].map(renderClipPath)}</defs>
            </svg>
        </>
    );
}

export default React.memo(SVGClipPaths);
