import { applyRotation, getPiecePosition } from './GameStateUtils';
import createGameConfig from '../GameConfig';

export const Actions = {
    Rotate: 'rotate',
    OnDrop: 'on-drop',
    BringToTop: 'bring-to-top',
    ResizeWindow: 'resize-window',
    ClearLocalMove: 'clear-local-move',
};

export function initGameState(puzzleConfig) {
    const config = createGameConfig(
        puzzleConfig.puzzle,
        puzzleConfig.size.rows,
        puzzleConfig.size.cols
    );

    const groupIndices = new Array();
    const groups = new Array();

    let index = -1;
    for (let row = 0; row < config.numRows; ++row) {
        for (let col = 0; col < config.numCols; ++col) {
            ++index;
            groupIndices.push(index);

            const left = (config.unitWidth + config.gap) * col;
            const top = (config.unitHeight + config.gap) * row;

            groups.push({
                refIndex: index,
                refLeft: left,
                refTop: top,
                rotation: 0,
                zIndex: index,
                pieces: [index],
            });
        }
    }

    return {
        config: config,
        windowSize: { width: window.innerWidth, height: window.innerHeight },
        groupIndices: groupIndices,
        groups: groups,
        maxZIndex: index,
        localMoves: [],
    };
}

export function reduceGameState(state, action) {
    switch (action.type) {
        case Actions.ResizeWindow: {
            return { ...state, windowSize: action.payload.ws };
        }

        case Actions.Rotate: {
            return updateGroup(state, action.payload.group, (group) => {
                return rotateGroup(group, action.payload.piece, state.config);
            });
        }

        case Actions.BringToTop: {
            const groupIndex = action.payload.group;
            const group = state.groups[groupIndex];
            if (group.zIndex == state.maxZIndex) {
                return state;
            }
            return updateGroup(state, groupIndex, (group) => {
                return group;
            });
        }

        case Actions.OnDrop: {
            const candidateState = updateGroup(state, action.payload.group, (group) => {
                return {
                    ...group,
                    refLeft: group.refLeft + action.payload.dx,
                    refTop: group.refTop + action.payload.dy,
                };
            });
            return handleDrop(candidateState, action.payload.group);
        }

        case Actions.ClearLocalMove: {
            if (state.localMoves.length == 0) return state;
            else {
                const newLocalMoves = state.localMoves.slice(action.payload.count); // drop the N elements
                return { ...state, localMoves: newLocalMoves };
            }
        }

        default:
            throw new Error('Unrecognized dispatch action: ' + action.type);
    }
}

function updateGroup(state, index, f) {
    const newMaxZIndex =
        state.maxZIndex == state.groups[index].zIndex ? state.maxZIndex : state.maxZIndex + 1;

    const newGroups = state.groups.map((group) => {
        if (group != null && group.refIndex == index) {
            return f({ ...group, zIndex: newMaxZIndex });
        } else {
            return group;
        }
    });

    return { ...state, groups: newGroups, maxZIndex: newMaxZIndex };
}

function rotateGroup(group, pi, config) {
    const newRotation = group.rotation + 1;

    const [newRefLeft, newRefTop] = (() => {
        if (pi == group.refIndex) {
            // Piece clicked is refIndex. No adjustment required.
            return [group.refLeft, group.refTop];
        } else {
            // Otherwise adjust the left/top coordinates to rotate around the piece clicked.
            const [pLeft, pTop] = getPiecePosition(pi, group, config);
            const [dLeft, dTop] = [group.refLeft - pLeft, group.refTop - pTop];
            const [dLeft2, dTop2] = applyRotation(1, dLeft, dTop);
            return [pLeft + dLeft2, pTop + dTop2];
        }
    })();

    return {
        ...group,
        rotation: newRotation,
        refLeft: newRefLeft,
        refTop: newRefTop,
    };
}

function handleDrop(state, groupIndex) {
    // Collect all pieces in the dropped group.
    const candidatePieces = [...state.groups[groupIndex].pieces];

    let candidateState = state;

    let mergeCount = 0;
    const mergeCountMax = 10;

    while (candidatePieces.length > 0) {
        const pi = candidatePieces.pop();
        const pGroup = getGroupForPieceIndex(candidateState, pi);

        const nGroup = findMatchForPiece(candidateState, pi);

        if (nGroup) {
            if (++mergeCount > mergeCountMax) {
                console.log('Reached mergeCountMax=', mergeCountMax);
                break;
            }

            // Found a match, merge the two groups.
            candidateState = mergeGroup(candidateState, pGroup.refIndex, nGroup.refIndex);

            // Retry the current piece.
            candidatePieces.push(pi);
        }
    }

    return candidateState;
}

function getGroupForPieceIndex(state, pi) {
    const gi = state.groupIndices[pi];
    return state.groups[gi];
}

function findMatchForPiece(state, pi) {
    const pGroup = getGroupForPieceIndex(state, pi);

    // Figure out the row & col indices.
    const gc = state.config;
    const row = Math.floor(pi / gc.numCols);
    const col = pi % gc.numCols;

    // Figure out the neighboring pieces.
    const neighbors = [];
    if (row != 0) neighbors.push(-gc.numCols);
    if (row != gc.numRows - 1) neighbors.push(+gc.numCols);
    if (col != 0) neighbors.push(-1);
    if (col != gc.numCols - 1) neighbors.push(+1);

    for (const deltaIndex of neighbors) {
        const nIndex = pi + deltaIndex;

        const nGroup = getGroupForPieceIndex(state, nIndex);
        if (nGroup.refIndex == pGroup.refIndex) {
            continue;
        }

        if ((nGroup.rotation - pGroup.rotation) % 4 != 0) {
            continue;
        }

        // Position of the p-piece.
        const [left0, top0] = getPiecePosition(pi, pGroup, state.config);

        // Calculate the expected position difference between n-Piece and p-Piece.
        // Note that the rotation of the groups is applied.
        let [dx0, dy0] = [0, 0];
        if (Math.abs(deltaIndex) == 1) {
            // The two piece indices are horizontally adjacent.
            dx0 = gc.unitWidth * deltaIndex;
        } else {
            // The two piece indices are vertically adjacent.
            dy0 = gc.unitHeight * Math.sign(deltaIndex);
        }
        const [dx, dy] = applyRotation(pGroup.rotation, dx0, dy0);

        // Expected position of the n-piece.
        const [left, top] = [left0 + dx, top0 + dy];

        // Actual position of the n-piece.
        const [nLeft, nTop] = getPiecePosition(nIndex, nGroup, state.config);

        // Calculate the distance.
        const square = (x) => x * x;
        const dist = square(nLeft - left) + square(nTop - top);

        if (dist <= gc.distanceTolerance) {
            return nGroup;
        }
    }

    return null;
}

function mergeGroup(state, srcGroupIndex, tgtGroupIndex) {
    //console.log('mergeGroup: src=', srcGroupIndex, ', tgt=', tgtGroupIndex);

    const srcGroup = getGroupForPieceIndex(state, srcGroupIndex);
    const tgtGroup = getGroupForPieceIndex(state, tgtGroupIndex);

    const move = [srcGroupIndex, tgtGroupIndex];
    const newLocalMoves = [...state.localMoves, move];

    const newTgtGroup = {
        ...tgtGroup,
        pieces: [...tgtGroup.pieces, ...srcGroup.pieces],
    };

    const newGroupIndices = state.groupIndices.map((gi) =>
        gi == srcGroupIndex ? tgtGroupIndex : gi
    );

    const newGroups = state.groups.map((g) => {
        if (g != null) {
            if (g.refIndex == srcGroupIndex) return null;
            if (g.refIndex == tgtGroupIndex) return newTgtGroup;
        }
        return g;
    });

    return {
        ...state,
        groupIndices: newGroupIndices,
        groups: newGroups,
        localMoves: newLocalMoves,
    };
}
