import Dashboard from './components/Dashboard';
import { AuthProvider } from './contexts/AuthContext';

export default function App() {
    return (
        <AuthProvider>
            <Dashboard />
        </AuthProvider>
    );
}
